<template>
    <div>
        <div class="workshopsGallery">
            <vue-slick-carousel v-bind="slider" ref="carousel">
                <div class="workshopsGallery__slide">
                    <div class="workshopsGallery__slide--image">
                        <img loading="lazy" :src="image.file" alt="" />
                    </div>
                </div>
            </vue-slick-carousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'workshopsGallery',
    props: {
        image: Object,
    },
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            slider: {
                infinite: true,
                draggable: true,
                centerMode: false,
                dots: true,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.workshopsGallery {
    position: relative;
    margin-top: rem(15px);
    $self: &;
    &__slide {
        text-align: center;
        &--image {
            position: relative;
            img {
                max-width: 100%;
                max-height: 400px;
                border: 2px solid var(--secondary);
            }
        }
    }
    .slick-current {
        #{$self}__slide {
            &--image {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                // @include flex;
                // border: solid 2px #009b74;
                // background: #009b74;
            }
        }
    }
}
</style>
