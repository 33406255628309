<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->
        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="profileWorkshps">
                                <div class="filters--by">
                                    <label class="control-label">{{
                                        $t('Filter by')
                                    }}</label>
                                    <v-select
                                        :options="selectOptions"
                                        v-model="status"
                                    ></v-select>
                                    <!-- <div
                                        v-if="status.value"
                                        @click="clear"
                                        class="clear"
                                    >
                                        <svg
                                            fill="#009b74"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 409.81 409.81"
                                        >
                                            <use
                                                xlink:href="#menu-close-icon"
                                            ></use>
                                        </svg>
                                    </div> -->
                                </div>
                                <workshop-card
                                    v-for="(workshop, i) in workshops"
                                    :workshop="workshop"
                                    :key="i"
                                    @showPopUp="showPopUp(workshop)"
                                ></workshop-card>
                                <base-loader v-if="workshopsLoadding" />
                                <div
                                    v-if="
                                        !workshopsLoadding &&
                                            !workshops.length &&
                                            dashboardSettings &&
                                            dashboardSettings.dashboard_settings
                                    "
                                    class="no-workshop"
                                >
                                    <p
                                        class="no-workshop-text"
                                        v-html="
                                            dashboardSettings
                                                .dashboard_settings[
                                                `no_sprint_msg_${$i18n.locale}`
                                            ]
                                        "
                                        @click="
                                            status =
                                                status &&
                                                status.value === 'upcoming'
                                                    ? selectOptions[1]
                                                    : selectOptions[0]
                                        "
                                    ></p>
                                </div>
                            </div>
                            <!-- profileWorkshop popUp -->
                            <profile-workshop
                                v-if="workshop"
                                ref="popUp"
                                :workshop="workshop"
                                :isUpcomingSprint="status.value === 'upcoming'"
                                @reloadSprints="reloadSprints"
                            />
                            <!-- profileWorkshop popUp -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/pitch-events-banner-1200X600.png'
import imgLarge from '@/assets/images/banners/pitch-events-banner-1600X700.png'
import imgBig from '@/assets/images/banners/pitch-events-banner-1900X595.png'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { WorkshopHelper } from '@/common/crud-helpers/workshop'
import ProfileWorkshop from '@/modules/workshop/components/details/ProfileWorkshop.vue'
import WorkshopCard from '@/modules/workshop/components/list/WorkshopCard.vue'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
export default {
    components: {
        WorkshopCard,
        ProfileWorkshop,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            selectOptions: [
                // {
                //     text: this.$t('Nothing selected'),
                //     value: '',
                //     disabled: true,
                // },
                {
                    text: this.$t('Upcoming sprints'),
                    value: 'upcoming',
                },
                {
                    text: this.$t('Past sprints'),
                    value: 'past',
                },
            ],
            filters: {
                page_size: 6,
                page: 1,
            },
            workshops: [],
            workshop: {},
            workshopsLoadding: true,
            status: {
                text: this.$t('Upcoming sprints'),
                value: 'upcoming',
            },
            dateToday: '',
        }
    },
    async created() {
        const today = new Date()
        const mmToday = today.getMonth() + 1
        const ddToday = today.getDate()
        const yyToday = today.getFullYear()
        this.dateToday = `${yyToday}-${mmToday}-${ddToday}`
        this.filters.event_date_from = this.dateToday
        this.filters.ordering = 'event_date'
        this.getWorkshops()

        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        reloadSprints(isSprintCanceled) {
            if (isSprintCanceled) {
                this.workshop = null
                this.workshops = []
                this.getWorkshops()
            }
        },
        getWorkshops() {
            this.workshopsLoadding = true
            WorkshopHelper.myWorkshopList(this.filters)
        },
        changeFilters() {
            this.filters.page = 1
            if (this.status) {
                if (this.status.value == 'past') {
                    this.filters.event_date_to = this.dateToday
                    this.filters.event_date_from = ''
                    this.filters.ordering = '-event_date'
                } else if (this.status.value == 'upcoming') {
                    this.filters.event_date_to = ''
                    this.filters.event_date_from = this.dateToday
                    this.filters.ordering = 'event_date'
                }
            }

            this.workshops = []
            this.getWorkshops()
        },
        async showPopUp(workshop) {
            this.workshop = await cloneDeep(workshop)
            this.$bvModal.show('profileWorkshops')
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.workshopsLoadding) {
                    let next = this.getNextPage(this.workshopList.next)
                    if (next) {
                        this.workshopsLoadding = true
                        this.filters.page = next
                        this.getWorkshops()
                    }
                }
            }
        },
        // clear() {
        //     ;(this.status = {
        //         text: this.$t('Nothing selected'),
        //         value: '',
        //     }),
        //         delete this.filters.event_date_to
        //     delete this.filters.event_date_from
        //     this.filters.page = 1
        //     this.workshops = []
        //     // this.getWorkshops()
        // },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
                ? activeTab.value[`title_${this.$i18n.locale}`]
                : this.$t('Sprints')
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['workshopList', 'dashboardSettings']),
    },
    watch: {
        workshopList() {
            let allWorkshops = _.concat(
                this.workshops,
                this.workshopList.results,
            )
            this.workshops = cloneDeep(allWorkshops)
            this.workshopsLoadding = false
        },
        status: function() {
            this.changeFilters()
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
.no-workshop {
    .no-workshop-text {
        cursor: pointer;
    }
    padding-left: 1.875rem;
}
.clear {
    width: 13px;
    position: absolute;
    top: 21px;
    right: 60px;
    cursor: pointer;
}
.sidenav-wrapper {
    margin-top: 0;
    /deep/ .right-col {
        @media screen and (min-width: 1600px) {
            padding-top: 20px;
        }
        @media screen and (max-width: 1599px) {
            padding-top: 30px;
        }
        @media screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        @media screen and (max-width: 991px) {
            padding-top: 0px;
        }
    }
}
.content-wrapper {
    /deep/ {
        .page__head {
            min-height: auto;
        }
    }
}
.page-contents {
    padding-top: rem(100px);
}
.profileWorkshps {
    margin-top: rem(25px);
    .filters {
        &--by {
            margin-bottom: rem(80px);
            /deep/.v-select {
                .v-select-toggle {
                    &:focus,
                    &:active {
                        color: var(--white) !important;
                    }
                }
            }
            @media screen and (max-width: 575px) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding-left: rem(20px);
                label {
                    margin: 0 0 rem(16px) 0;
                }
            }
        }
    }
}
/deep/ {
    .filters {
        &--by {
            @include flex(center, flex-end);
            .v-select {
                width: 235px;
                .v-select-toggle {
                    color: var(--primary) !important;
                    font-size: rem(18px) !important;
                    padding-top: rem(13px) !important;
                    padding-bottom: rem(13px) !important;
                    .arrow-down {
                        zoom: 0.5;
                    }
                }
            }
            .control-label {
                color: #fff;
                margin-right: rem(30px);
            }
        }
    }
    @media screen and (max-width: 1199px) {
        .content-wrapper {
            .page__head {
                min-height: 120px;
            }
        }
        .page-banner {
            --h: 620px;
        }
    }
    @media screen and (max-width: 991px) {
        .page-banner {
            --h: 530px;
        }
    }
    @media screen and (max-width: 767px) {
        .content-wrapper {
            .page__head {
                min-height: 130px;
            }
        }
        .page-banner {
            --h: 400px;
        }
        .page-contents {
            padding-top: rem(36px);
        }
    }
    @media screen and (max-width: 575px) {
        .page-contents {
            padding-top: 0;
        }
    }
}

.filters--by {
    position: relative;
}
// ar style

.ar {
    .no-workshop {
        padding-left: 0;
        padding-right: 1.875rem;
    }
    .profileWorkshps {
        .filters {
            &--by {
                @media screen and (max-width: 575px) {
                    padding-right: rem(20px);
                    padding-left: 0;
                }
            }
            /deep/ {
                .clear {
                    left: auto;
                    right: 193px;
                }
            }
        }
    }
    .filters--by .control-label {
        margin-left: 1.875rem;
        margin-right: 0;
    }
    .clear {
        width: 13px;
        position: absolute;
        top: 21px;
        left: 60px;
        cursor: pointer;
        right: auto;
    }
}
</style>
