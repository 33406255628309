<template>
    <div>
        <!-- profileWorkshops Modal -->
        <b-modal
            id="profileWorkshops"
            hide-footer
            @hidden="$emit('reloadSprints', isToogled)"
        >
            <div class="profileworkshop">
                <p v-if="workshop[`title_${$i18n.locale}`]" class="title">
                    {{ workshop[`title_${$i18n.locale}`] }}
                </p>
                <div class="profileworkshop-date-contributors">
                    <p class="date">
                        <span>{{ workshop.event_date | dateDay }}</span>
                        {{ workshop.event_date | dateMonthYear }} -
                        <span class="workshop">{{ $t('Sprint') }}</span>
                    </p>
                    <a
                        v-b-modal.profile
                        class="contributors"
                        data-dismiss="modal"
                        href="#"
                        >{{ $t('No of Participants') }} –
                        <span>{{ registrations.length }}</span></a
                    >
                </div>
                <!-- Gallery -->
                <gallery
                    v-if="workshop.image"
                    :image="workshop.image"
                ></gallery>
                <!-- Gallery -->
                <div class="workshopsDetail">
                    <div class="row">
                        <div class="col-sm-6">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <img
                                            loading="lazy"
                                            src="@/assets/images/svg/clock.svg"
                                            alt=""
                                        />
                                    </div>
                                    <i class="ltr">
                                        {{ workshop.start_time }}
                                        {{ workshop.start_time | ampm }} -
                                        {{ workshop.end_time }}
                                        {{ workshop.end_time | ampm }}
                                    </i>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <div class="icon">
                                        <img
                                            loading="lazy"
                                            src="@/assets/images/svg/location.svg"
                                            alt=""
                                        />
                                    </div>
                                    {{
                                        workshop.location_type == 'online'
                                            ? $t('Online')
                                            : workshop.location
                                    }}
                                </li>
                            </ul>
                            <ul v-if="isUpcomingSprint">
                                <li>
                                    <div class="button-row">
                                        <div
                                            class="btn btn-pink"
                                            @click="toggleRegistration()"
                                        >
                                            {{
                                                isCanceled
                                                    ? $t('Register now')
                                                    : $t('Cancel Registration')
                                            }}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <ul>
                                <li>
                                    <div class="icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 26 25"
                                        >
                                            <image
                                                id="speaker_copy"
                                                data-name="speaker copy"
                                                xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAYAAAAv3j5gAAABqElEQVRIib2Vu0vDUBSHP0sdCk4OooOOoihaaUUXsX+Ag/iCTjqIo518YEFwCIKTmZydRB1cfYGbggS6CILoZK2bgh1ErQ8uuZDak7Rptf2m9ju595fTnN7UzUdW8MkosAxEgG/gHFgzLePMz/KAMO4sAgfAABAE6oFh4DQRTU67rqggqBNYF9ZZv5WIJptFxeXCUsRLXBcCxoStIKhNGEmrVOUHZYSRPEpVftC+ML/50INSFD9BKWBDWIcF0zLuhS3A73gvAbPAXZ67AiZMyzDF1S6U+sMmgBkddKxdI5ADXvT3KWAVMEzL2BE7aIp1NAJsAmHgCNgD+oEnHRIDDoFdoAvYTkSTg2IXjVdHDcAN0CIq0K39iajANdBjWkbOb0dzHiGKcfVshLVRp8iksEWC4sI4XAKWsCXWBoWxw3uFtfnSp3aTqDiEhfHoSJ3M78LapPQg3AIPomrzKYxHR29Au8ddP+d9jumhKcT1BtTUqZ+pT1T+l5Tq6EIf9dXkNVCDEEXIbRiqQsDn++avZFRQBzBUxRC1d4cKygLpKgalTcvI1uYZAT86RlALZ9nTGgAAAABJRU5ErkJggg=="
                                            />
                                        </svg>
                                    </div>
                                    {{ speakers(workshop.speakers) }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="position-relative">
                        <div class="greyBg"></div>
                        <h3>{{ $t('Details') }}</h3>
                        <p>{{ workshop[`description_${$i18n.locale}`] }}</p>
                    </div>
                </div>
            </div>
        </b-modal>
        <!-- profileWorkshops Modal -->
        <div>
            <!-- followers Modal -->
            <b-modal id="profile" hide-footer>
                <div class="profile">
                    <p class="title">
                        {{ $t('no of contributors') }} –
                        {{ registrations.length }}
                    </p>
                    <base-followers
                        :followers="registrations"
                        :loadding="registrationsLoadding"
                        @loadMore="loadMoreRegistrations"
                    ></base-followers>
                </div>
            </b-modal>
            <!-- followers Modal -->
        </div>
    </div>
</template>

<script>
// import { IdeasHelper } from '@/common/crud-helpers/ideas'
import { WorkshopHelper } from '@/common/crud-helpers/workshop'
import Gallery from '@/modules/workshop/components/details/Gallery'
import { speakers } from '@/modules/workshop/mixins/speakers'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    mixins: [speakers],
    props: {
        workshop: Object,
        isUpcomingSprint: Boolean,
    },
    components: {
        Gallery,
    },
    data() {
        return {
            registrations: [],
            registrationsLoadding: true,
            isCanceled: false,
            workshopId: null,
            workshopMember: null,
            isToogled: false,
        }
    },
    methods: {
        loadMoreRegistrations() {
            let next = this.getNextPage(this.followersList.next)
            if (!this.registrationsLoadding && next) {
                this.registrationsLoadding = true
                WorkshopHelper.getUserRegistration({
                    uuid: this.uuid(this.workshop.uuid),
                    page_size: 12,
                    page: next,
                })
            }
        },
        toggleRegistration() {
            if (this.isCanceled) {
                WorkshopHelper.userRegistration({
                    uuid: this.uuid(this.workshop.uuid),
                }).then(resp => {
                    if (resp.data.id) {
                        this.workshopId = resp.data.id
                        this.isCanceled = false
                        this.isToogled = true
                    } else {
                        this.isCanceled = true
                    }
                })
            } else {
                WorkshopHelper.cancelUserRegistration({
                    uuid: this.uuid(this.workshop.uuid),
                    id: this.workshopId,
                })
                    .then(resp => {
                        if (resp) {
                            this.isCanceled = true
                            this.isToogled = true
                            let index = this.registrations.indexOf(
                                this.workshopMember,
                            )
                            if (index !== -1) {
                                this.registrations.splice(index, 1)
                            }
                        } else {
                            this.isCanceled = false
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        this.isCanceled = false
                    })
            }
        },
    },
    created() {},
    computed: {
        ...mapGetters(['userRegistrations', 'userAuth']),
    },
    watch: {
        workshop: {
            handler() {
                this.registrations = []
                WorkshopHelper.getUserRegistration({
                    uuid: this.uuid(this.workshop.uuid),
                })
            },
            deep: true,
        },
        userRegistrations() {
            let allRegistrations = _.concat(
                this.registrations,
                this.userRegistrations.results,
            )
            this.registrations = cloneDeep(allRegistrations)
            this.registrationsLoadding = false
            this.workshopMember = this.registrations.find(
                registration => registration.user === this.userAuth.id,
            )
            this.workshopId = this.workshopMember.id
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/.modal {
    .modal-dialog {
        .modal-content {
            border-radius: 0px;
            position: relative;
            overflow: hidden;
            padding-bottom: 0;
        }
    }
    .profileworkshop {
        .title {
            @extend .gradiantTitle;
            background: -webkit-gradient(
                linear,
                left top,
                right bottom,
                from(var(--primary)),
                to(var(--secondary))
            ) !important;
            -webkit-background-clip: text !important;
        }
        .profileworkshop-date-contributors {
            display: flex;
            justify-content: space-between;
            margin-top: rem(25px);
            p {
                margin: 0;
                font-size: rem(15px);
                color: var(--primary);
            }
            .date {
                font-size: rem(14px);
                font-weight: 700;
                span {
                    color: var(--primary);
                    font-size: rem(26px);
                    font-weight: 700;
                }
                .workshop {
                    font-size: rem(20px);
                    font-weight: 600;
                    color: var(--secondary);
                }
            }
            .contributors {
                @extend .date;
                text-transform: uppercase;
                text-decoration: none !important;
            }
        }
        .workshopsDetail {
            padding-top: rem(80px);
            .greyBg {
                z-index: 1;
                top: rem(-40px);
                width: 733px;
            }
            h3 {
                font-size: rem(24px);
                font-weight: 900;
                color: var(--primary);
                text-transform: uppercase;
                margin-bottom: rem(20px);
                position: relative;
                z-index: 2;
            }
            p {
                position: relative;
                z-index: 2;
                font-size: rem(18px);
                color: #2a2a2a;
                line-height: 1.4;
                max-width: 690px;
                margin-bottom: rem(20px);
            }
            .row {
                padding-left: rem(40px);
                padding-bottom: rem(80px);
            }
            ul {
                list-style: none;
                li {
                    font-size: rem(24px);
                    font-weight: 600;
                    color: var(--primary);
                    line-height: 2;
                    display: flex;
                    text-align: left;
                    .btn-pink {
                        background: var(--primary);
                        &:hover {
                            background-color: var(--green);
                            color: white;
                            border-color: var(--green);
                        }
                    }
                    .icon {
                        margin-right: rem(30px);
                        color: var(--primary);
                        svg {
                            width: 25px;
                            height: auto;
                        }
                        img {
                            width: 25px;
                            height: auto;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .workshopsDetail {
                .row {
                    padding-left: 0;
                }
                ul {
                    li {
                        font-size: rem(18px);
                        .icon {
                            svg {
                                width: 18px;
                            }
                            img {
                                width: 18px;
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 450px) {
            .profileworkshop-date-contributors {
                flex-direction: column;
            }
        }
    }
    .profile {
        padding-bottom: 0;
        .title {
            @extend .gradiantTitle;
            text-transform: capitalize;
            @extend .gradiantTitle;
            background: -webkit-gradient(
                linear,
                left top,
                right bottom,
                from(var(--primary)),
                to(var(--secondary))
            ) !important;
            -webkit-background-clip: text !important;
        }
        /deep/.scrollWrapper {
            height: 350px;
        }
    }
    &#profile {
        .modal-content {
            padding: rem(30px);
        }
    }
    i {
        font-style: normal;
    }
}

.ar {
    .modal {
        .profileworkshop {
            .profileworkshop-date-contributors {
                .date {
                    direction: ltr;
                }
            }
            /deep/ {
                .title {
                    &:after {
                        right: 0;
                        left: auto;
                    }
                }
            }
            .workshopsDetail {
                .row {
                    padding-right: rem(40px);
                    padding-left: 0;
                }
                ul {
                    li {
                        text-align: right;
                        .icon {
                            margin-left: rem(30px);
                            margin-right: 0;
                        }
                    }
                }
            }
            @media screen and (max-width: 991px) {
                .workshopsDetail {
                    .row {
                        padding-right: 0;
                    }
                }
            }
        }
        &#profile {
            .modal-content {
                /deep/.title {
                    &:after {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
        .profile {
            .title {
                &:after {
                    right: 0;
                    left: auto !important;
                }
            }
        }
    }
}
</style>
