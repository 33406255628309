var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-inner-banner',{attrs:{"banner-image":_vm.pageBanner,"is-custom-banner":true,"banner":_vm.banner}}),_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"container"},[_c('base-page-head',{attrs:{"page-head":_vm.pagehead}}),_c('div',{staticClass:"page-contents userAccount"},[_c('div',{staticClass:"sidenav-wrapper"},[_c('aside',{staticClass:"left-col"},[_c('account-nav')],1),_c('div',{staticClass:"right-col"},[_c('div',{staticClass:"profileWorkshps"},[_c('div',{staticClass:"filters--by"},[_c('label',{staticClass:"control-label"},[_vm._v(_vm._s(_vm.$t('Filter by')))]),_c('v-select',{attrs:{"options":_vm.selectOptions},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_vm._l((_vm.workshops),function(workshop,i){return _c('workshop-card',{key:i,attrs:{"workshop":workshop},on:{"showPopUp":function($event){return _vm.showPopUp(workshop)}}})}),(_vm.workshopsLoadding)?_c('base-loader'):_vm._e(),(
                                    !_vm.workshopsLoadding &&
                                        !_vm.workshops.length &&
                                        _vm.dashboardSettings &&
                                        _vm.dashboardSettings.dashboard_settings
                                )?_c('div',{staticClass:"no-workshop"},[_c('p',{staticClass:"no-workshop-text",domProps:{"innerHTML":_vm._s(
                                        _vm.dashboardSettings
                                            .dashboard_settings[
                                            ("no_sprint_msg_" + (_vm.$i18n.locale))
                                        ]
                                    )},on:{"click":function($event){_vm.status =
                                            _vm.status &&
                                            _vm.status.value === 'upcoming'
                                                ? _vm.selectOptions[1]
                                                : _vm.selectOptions[0]}}})]):_vm._e()],2),(_vm.workshop)?_c('profile-workshop',{ref:"popUp",attrs:{"workshop":_vm.workshop,"isUpcomingSprint":_vm.status.value === 'upcoming'},on:{"reloadSprints":_vm.reloadSprints}}):_vm._e()],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }