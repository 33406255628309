<template>
    <div>
        <div class="listing__item" data-aos="fade-up">
            <div class="listing__item--date">
                <span>{{ workshop.event_date | dateDay }}</span>
                {{ workshop.event_date | dateMonthYear }}-
                <span class="time"
                    >{{ workshop.start_time }}
                    {{ workshop.start_time | ampm }} – {{ workshop.end_time }}
                    {{ workshop.end_time | ampm }}</span
                >
            </div>
            <div class="listing__item--body">
                <div class="d-flex justify-content-between">
                    <div class="listing__item--contents">
                        <p class="listing__item--title">
                            {{ workshop[`title_${$i18n.locale}`] }}
                        </p>
                        <!-- <p
                            class="listing__item--shortdes"
                            v-if="
                                workshop.shortdes !== null &&
                                    workshop.shortdes != ''
                            "
                        >
                            {{ workshop.shortdes }}
                        </p> -->
                    </div>
                    <div class="listing__item--image" v-if="workshop.image">
                        <img
                            loading="lazy"
                            :src="workshop.image.file"
                            alt=""
                            class="image-top"
                        />
                        <img loading="lazy" :src="workshop.image.file" alt="" />
                    </div>
                </div>
                <!-- Likes -->
                <div class="listing__item--info">
                    <ul class="list-inline">
                        <li>
                            <div class="icon">
                                <img
                                    loading="lazy"
                                    src="@/assets/images/svg/location.svg"
                                    alt=""
                                />
                            </div>
                            <span
                                >{{
                                    workshop.location_type == 'online'
                                        ? $t('Online')
                                        : workshop.location
                                }}
                            </span>
                        </li>
                        <li>
                            <div class="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 26 25"
                                >
                                    <image
                                        id="speaker_copy"
                                        data-name="speaker copy"
                                        xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAZCAYAAAAv3j5gAAABqElEQVRIib2Vu0vDUBSHP0sdCk4OooOOoihaaUUXsX+Ag/iCTjqIo518YEFwCIKTmZydRB1cfYGbggS6CILoZK2bgh1ErQ8uuZDak7Rptf2m9ju595fTnN7UzUdW8MkosAxEgG/gHFgzLePMz/KAMO4sAgfAABAE6oFh4DQRTU67rqggqBNYF9ZZv5WIJptFxeXCUsRLXBcCxoStIKhNGEmrVOUHZYSRPEpVftC+ML/50INSFD9BKWBDWIcF0zLuhS3A73gvAbPAXZ67AiZMyzDF1S6U+sMmgBkddKxdI5ADXvT3KWAVMEzL2BE7aIp1NAJsAmHgCNgD+oEnHRIDDoFdoAvYTkSTg2IXjVdHDcAN0CIq0K39iajANdBjWkbOb0dzHiGKcfVshLVRp8iksEWC4sI4XAKWsCXWBoWxw3uFtfnSp3aTqDiEhfHoSJ3M78LapPQg3AIPomrzKYxHR29Au8ddP+d9jumhKcT1BtTUqZ+pT1T+l5Tq6EIf9dXkNVCDEEXIbRiqQsDn++avZFRQBzBUxRC1d4cKygLpKgalTcvI1uYZAT86RlALZ9nTGgAAAABJRU5ErkJggg=="
                                    />
                                </svg>
                            </div>
                            <span>{{ speakers(workshop.speakers) }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <a @click="$emit('showPopUp', $event)" class="abs-link"></a>
        </div>
    </div>
</template>

<script>
import { speakers } from '@/modules/workshop/mixins/speakers.js'
export default {
    mixins: [speakers],
    components: {},
    props: {
        workshop: Object,
    },
}
</script>

<style lang="scss" scoped>
/deep/ {
    .listing__item {
        padding-left: rem(30px);
        position: relative;
        &--info {
            svg {
                width: 33px;
                height: auto;
                filter: brightness(0) saturate(100%) invert(41%) sepia(95%) saturate(646%) hue-rotate(123deg) brightness(88%) contrast(101%);
                &.location {
                    width: 25px;
                }
            }
            ul {
                flex-wrap: nowrap;
                li {
                    @include flex(flex-start, flex-start);
                    flex-wrap: nowrap;
                    color: var(--primary);
                    font-size: rem(18px);
                    line-height: 1.3;
                    &:not(:last-child) {
                        margin-right: rem(60px);
                    }
                    span {
                        width: 150px;
                        font-weight: 600;
                        padding-top: 12px;
                    }
                    .icon {
                        color: var(--primary);
                        margin-right: rem(16px);
                        img {
                            width: 33px;
                            filter: brightness(0) saturate(100%) invert(41%) sepia(95%) saturate(646%) hue-rotate(123deg) brightness(88%) contrast(101%);
                            @media screen and (max-width: 575px) {
                                width: 22px;
                            }
                        }
                    }
                }
            }
        }
        &--contents {
            flex: 0 0 70%;
            max-width: 70%;
        }
        &--date {
            display: flex;
            align-items: flex-end;
            .time {
                color: #e4d700;
                font-size: rem(25px);
                display: flex;
                align-items: flex-end;
                padding-left: rem(6px);
                i {
                    font-style: normal;
                    font-weight: 600;
                    font-size: rem(15px);
                    margin: 0 rem(6px) rem(3px) rem(6px);
                }
            }
        }
        &--image {
            img {
                max-width: 250px;
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &--contents {
                flex: 0 0 60%;
                max-width: 60%;
            }
            &--image {
                img {
                    max-width: 200px;
                }
            }
            &--info {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-right: rem(16px);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 992px) and (min-width: 768px) {
            &--info {
                ul {
                    flex-direction: column;
                    li {
                        &:not(:last-child) {
                            margin-right: 0;
                            margin-bottom: rem(20px);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            .listing {
                &__item {
                    &--contents {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }
                }
            }
            &--image {
                img {
                    max-width: 200px;
                }
            }
            &--info {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-right: rem(20px);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .listing {
                &__item {
                    &--contents {
                        flex: 0 0 100%;
                        max-width: 100%;
                        text-align: center;
                        margin: auto;
                    }
                    &--info {
                        ul {
                            justify-content: space-between;
                            li {
                                &:not(:last-child) {
                                    margin-right: 0;
                                }
                            }
                        }
                        svg {
                            width: 25px;
                            &.location {
                                width: 18px;
                            }
                        }
                    }
                    &--title {
                        &:after {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 575px) {
            .listing {
                &__item {
                    &--contents {
                        margin-top: -25px;
                    }
                    &--info {
                        ul {
                            flex-direction: column;
                            li {
                                width: 100%;
                                &:not(:last-child) {
                                    margin-bottom: rem(16px);
                                }
                                .icon {
                                    margin-top: rem(10px);
                                }
                            }
                        }
                    }
                    &--title {
                        font-size: rem(22px);
                    }
                    &--date {
                        padding-left: 0;
                        .time {
                            font-size: rem(18px);
                        }
                        span {
                            font-size: rem(18px);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 400px) {
            .listing {
                &__item {
                    &--title {
                        font-size: rem(20px);
                    }
                    &--date {
                        .time {
                            font-size: rem(16px);
                        }
                        span {
                            font-size: rem(16px);
                        }
                    }
                }
            }
        }
    }
}
.events--date {
    font-size: rem(14px);
    font-weight: 700;
    color: #4a1885;
    span {
        font-size: rem(25px);
        font-weight: 900;
        display: inline-block;
        padding-right: rem(5px);
        &.time {
            color: #4aa803;
            font-size: rem(20px);
            font-weight: 600;
        }
    }
}

// ar style

.ar {
    .listing__item {
        padding-right: rem(30px);
        padding-left: 0;
        &--info {
            ul {
                li {
                    &:not(:last-child) {
                        margin-left: rem(60px);
                        margin-right: 0;
                    }
                    .icon {
                        margin-left: rem(16px);
                        margin-right: 0;
                    }
                }
            }
        }
        &--date {
            .time {
                padding-right: rem(6px);
                padding-left: 0;
            }
        }
        @media screen and (max-width: 1199px) and (min-width: 992px) {
            &--info {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-left: rem(16px);
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 991px) {
            &--info {
                ul {
                    li {
                        &:not(:last-child) {
                            margin-left: rem(20px);
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            .listing {
                &__item {
                    &--info {
                        ul {
                            li {
                                &:not(:last-child) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 575px) {
            .listing {
                &__item {
                    &--date {
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .events--date {
        span {
            padding-left: rem(5px);
            padding-right: 0;
        }
    }
}
</style>
